import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';
import { useEffect, useRef } from 'react';

const baseURL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/esm';
export const useFFmpeg = () => {
  const ffmpegRef = useRef(new FFmpeg());

  useEffect(() => {
    (async () => {
      const ffmpeg = ffmpegRef.current;
      if (ffmpeg.loaded) return;
      await ffmpeg.load({
        coreURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.js`,
          'text/javascript',
        ),
        wasmURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.wasm`,
          'application/wasm',
        ),
        workerURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.worker.js`,
          'text/javascript',
        ),
      });
    })();
  }, []);

  return { ffmpeg: ffmpegRef.current };
};
