import React from 'react';
import { VariantProps } from 'tailwind-variants';

import { tv } from './cn';
import { textStyles } from './textStyles';

const styles = tv({
  base: 'inline-flex cursor-pointer items-center',
  variants: {
    disabled: {
      true: 'cursor-not-allowed',
    },
    // TODO(jesse)[ELU-2647] - Fix border radius
    size: {
      s: textStyles.body({ size: 's', className: 'rounded-[6px]' }),
      m: textStyles.body({ size: 'm', className: 'rounded-[8px]' }),
      l: textStyles.body({ size: 'l', className: 'rounded-[10px]' }),
    },
    spacing: {
      default: '',
      compact: '',
    },
    type: {
      primary:
        'bg-brandGray900 text-brandGray100 hover:bg-brandGray800 hover:text-brandGray200',
      subtle:
        'bg-brandGray50 text-brandGray600 hover:bg-brandGray100 hover:text-brandGray700',
      outline:
        'border border-brandGray200 text-brandGray800 hover:border-brandGray100 hover:text-brandGray700',
      outlineSubtle:
        'border border-brandGray200 bg-white text-brandGray600 hover:border-brandGray100 hover:text-brandGray700',
      outlineFilled:
        'bg-brandGray50 text-brandGray600 hover:bg-brandGray100 hover:text-brandGray700',
      ghost: 'text-brandGray600 hover:text-brandGray700',
    },
  },
  compoundVariants: [
    {
      disabled: true,
      type: 'primary',
      className:
        'bg-brandGray50 text-brandGray400 hover:bg-brandGray50 hover:text-brandGray400',
    },
    {
      disabled: true,
      type: 'subtle',
      className:
        'bg-brandGray50 text-brandGray400 hover:bg-brandGray50 hover:text-brandGray400',
    },
    {
      disabled: true,
      type: 'outline',
      className:
        'border-brandGray100 bg-white text-brandGray400 hover:border-brandGray100 hover:bg-white hover:text-brandGray400',
    },
    {
      disabled: true,
      type: 'outlineSubtle',
      className:
        'border-brandGray100 bg-white text-brandGray400 hover:border-brandGray100 hover:bg-white hover:text-brandGray400',
    },
    {
      disabled: true,
      type: 'outlineFilled',
      className:
        'border-brandGray100 bg-brandGray50 text-brandGray400 hover:border-brandGray100 hover:bg-brandGray50 hover:text-brandGray400',
    },
    {
      disabled: true,
      type: 'ghost',
      className: 'text-brandGray400 hover:text-brandGray400',
    },
    {
      spacing: 'default',
      size: 's',
      className: 'h-[30px] px-2 py-1.5',
    },
    {
      spacing: 'default',
      size: 'm',
      className: 'h-[38px] px-3 py-2',
    },
    {
      spacing: 'default',
      size: 'l',
      className: 'h-[44px] px-4 py-2.5',
    },
    {
      spacing: 'compact',
      size: 's',
      className: 'h-[26px] px-2 py-1',
    },
    {
      spacing: 'compact',
      size: 'm',
      className: 'h-[34px] px-3 py-1.5',
    },
    {
      spacing: 'compact',
      size: 'l',
      className: 'h-[40px] px-4 py-2',
    },
  ],
  defaultVariants: {
    type: 'primary',
    spacing: 'default',
    size: 'm',
  },
});

export type NewButtonProps = VariantProps<typeof styles> & {
  text: string;
  onClick?: () => void;
};

export const NewButton: React.FC<NewButtonProps> = ({
  size,
  spacing,
  type,
  disabled,
  text,
  onClick,
}) => {
  const className = styles({ size, spacing, type, disabled });

  return (
    <button
      className={className}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
