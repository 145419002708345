import { Loader2 } from 'lucide-react';
import React from 'react';
import { match } from 'ts-pattern';

import { Box, NewButton } from '@eluve/components';
import { useSummary } from '@eluve/frontend-appointment-hooks';
import { useCaptureEvent } from '@eluve/posthog-react';

import {
  useTranscriptionMachineActor,
  useTranscriptionMachineSelector,
} from '../machines/transcription/TranscriptionMachineProvider';

interface AppointmentTranscriptionInSessionControlsProps {
  onEndSession?: () => void;
}

export const AppointmentTranscriptionInSessionControls: React.FC<
  AppointmentTranscriptionInSessionControlsProps
> = ({ onEndSession }) => {
  // TODO(deep): [ELU-2758] Use data from cache to determine if summary is available
  const { isSummaryAvailable } = useSummary();
  const appointmentId = useTranscriptionMachineSelector(
    (state) => state.context.appointmentId ?? '',
  );

  const captureEvent = useCaptureEvent({
    appointmentId,
  });

  const state = useTranscriptionMachineSelector((state) => {
    if (
      state.matches({
        InSession: {
          Recording: {
            MediaRecorder: 'Loading',
          },
        },
      })
    ) {
      return 'Loading';
    }

    if (
      state.matches({
        InSession: 'Recording',
      })
    ) {
      return 'Recording';
    }

    if (
      state.matches({
        InSession: 'Paused',
      })
    ) {
      return 'Paused';
    }
  });

  const { send } = useTranscriptionMachineActor();

  const endSession = () => {
    send({ type: 'STOP', hasExistingSummary: isSummaryAvailable });
    onEndSession?.();
  };

  return match(state)
    .with('Recording', () => (
      <Box hStack>
        <NewButton
          onClick={() => {
            captureEvent('paused_session');
            send({ type: 'PAUSE' });
          }}
          type="subtle"
          size="m"
          text="Pause"
        />

        <NewButton
          size="m"
          text="Generate Note"
          onClick={() => {
            captureEvent('ended_session');
            endSession();
          }}
        />
      </Box>
    ))
    .with('Paused', () => (
      <Box hStack>
        <NewButton
          type="subtle"
          size="m"
          text="Resume Session"
          onClick={() => {
            captureEvent('resumed_session');
            send({ type: 'RESUME' });
          }}
        />

        <NewButton
          size="m"
          text="Generate Note"
          onClick={() => {
            captureEvent('ended_session', {
              wasPausedWhenEnded: true,
            });
            endSession();
          }}
        />
      </Box>
    ))
    .with('Loading', () => (
      <Box hStack>
        <Loader2 className="mr-2 h-5 animate-spin text-brand-9" />
        Processing
      </Box>
    ))
    .otherwise(() => null);
};
