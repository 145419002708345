import { fetchFile } from '@ffmpeg/util';
import { useEffect, useState } from 'react';

import { useFFmpeg } from './useFFmpeg';

export const AudioPlayer: React.FC<{ audioUrl?: string }> = ({ audioUrl }) => {
  const { ffmpeg } = useFFmpeg();
  const [blobUrl, setBlobUrl] = useState<string>('');

  useEffect(() => {
    const loadAndProcessAudio = async () => {
      if (blobUrl || !audioUrl || !ffmpeg.loaded) return;
      await ffmpeg.writeFile('input.webm', await fetchFile(audioUrl));
      await ffmpeg.exec([
        '-i',
        'input.webm',
        '-c',
        'copy',
        '-movflags',
        '+faststart',
        'output.webm',
      ]);

      const fileData = await ffmpeg.readFile('output.webm');
      const data = new Uint8Array(fileData as ArrayBuffer);
      const processedBlob = new Blob([data.buffer], { type: 'audio/webm' });
      const url = URL.createObjectURL(processedBlob);
      setBlobUrl(url);
    };

    loadAndProcessAudio();
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [audioUrl, ffmpeg, blobUrl]);

  return (
    <audio src={blobUrl ?? ''} controls preload="metadata">
      Your browser does not support the audio element.
    </audio>
  );
};
