import { Box, ReskinFooter } from '@eluve/components';
import {
  useAppointmentId,
  useTenantId,
} from '@eluve/frontend-appointment-hooks';

import { TranscriptionWaveform } from '../machines/transcription/components/TranscriptionWaveform';
import { useAppointmentTabNav } from '../useAppointmentTabNav';

import { AppointmentTranscriptionPageControls } from './AppointmentTranscriptionPageControls';

export const AppointmentTranscriptionFooter: React.FC = () => {
  const appointmentId = useAppointmentId();
  const tenantId = useTenantId();
  const { tab } = useAppointmentTabNav();

  if (tab !== 'transcript') return null;

  return (
    <ReskinFooter>
      <Box
        spaceBetween
        className="mx-auto w-full max-w-[1024px] items-center gap-2"
      >
        <TranscriptionWaveform />

        <AppointmentTranscriptionPageControls
          tenantId={tenantId}
          appointmentId={appointmentId}
        />
      </Box>
    </ReskinFooter>
  );
};
