import { Box, NewButton } from '@eluve/components';
import {
  useAppointmentActivity,
  useAppointmentStatus,
  useIsAppointmentReadonly,
  useSummary,
  useUpdateAppointmentStatus,
} from '@eluve/frontend-appointment-hooks';
import { AppointmentStatusTypesLookup } from '@eluve/graphql-types';
import { useSelectedMicrophoneDevice } from '@eluve/smart-blocks';
import {
  useAppointmentTasksActor,
  useAppointmentTasksSelector,
} from '@eluve/user-local-files';

import { useTranscriptionMachineActor } from '../machines/transcription/TranscriptionMachineProvider';
import { useAppointmentTabNav } from '../useAppointmentTabNav';

interface TranscriptionControlsProps {
  tenantId: string;
  appointmentId: string;
}

export const AppointmentTranscriptionIdleControls: React.FC<
  TranscriptionControlsProps
> = ({ tenantId, appointmentId }) => {
  const { setTab } = useAppointmentTabNav();
  const isEndingSession = useAppointmentTasksSelector((s) =>
    s.context.tasks.some(
      (t) =>
        t.appointmentId === appointmentId && t.type === 'TASK.GENERATE_NOTE',
    ),
  );

  const { send } = useTranscriptionMachineActor();
  const { send: sendAppointmentTask } = useAppointmentTasksActor();
  const isReadonly = useIsAppointmentReadonly();
  const appointmentStatus = useAppointmentStatus();
  const updateAppointmentStatus = useUpdateAppointmentStatus();
  const [selectedMicId] = useSelectedMicrophoneDevice();
  const { isSummaryAvailable } = useSummary();
  const { isSummarizationInProgress } = useAppointmentActivity();

  const startSession = async () => {
    const microphoneName = (
      await navigator.mediaDevices.enumerateDevices()
    ).find((device) => device.deviceId === selectedMicId)?.label;

    send({
      type: 'START',
      appointmentId,
      deviceId: selectedMicId ?? undefined,
      microphoneName,
    });

    await updateAppointmentStatus({
      variables: {
        tenantId,
        appointmentId,
        status: AppointmentStatusTypesLookup.ACTIVE,
      },
    });
  };

  if (isReadonly) {
    return null;
  }

  return (
    <Box hStack>
      <NewButton
        type="subtle"
        size="m"
        text="Resume Session"
        onClick={startSession}
        disabled={isEndingSession || isSummarizationInProgress}
      />

      <NewButton
        size="m"
        text="Generate Note"
        onClick={() => {
          sendAppointmentTask({
            type: 'TASK.GENERATE_NOTE',
            appointmentId,
            hasExistingSummary: isSummaryAvailable,
          });
        }}
        disabled={
          isEndingSession ||
          appointmentStatus === AppointmentStatusTypesLookup.COMPLETED ||
          isSummarizationInProgress
        }
      />
    </Box>
  );
};
