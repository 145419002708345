import { Headphones } from 'lucide-react';
import React, { useState } from 'react';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import { TooltipLabel } from '@eluve/blocks';
import { Box, Button, HStack } from '@eluve/components';
import { AudioPlayer } from '@eluve/ffmpeg-utils';

export interface AppointmentFileActionsColumnProps {
  tenantId: string;
  appointmentId: string;
  fileId: string;
  filePath: string;
}

export const AppointmentFileActionsColumn: React.FC<
  AppointmentFileActionsColumnProps
> = ({ appointmentId, fileId, filePath, tenantId }) => {
  const api = useApiClient();
  const [audioUrl, setAudioUrl] = useState<string>('');

  const getFile = async () => {
    if (!filePath) return;
    const result = await api.adminAppointment.getAppointmentFileSignedUrl({
      params: { tenantId, appointmentId, fileId },
    });

    if (result.status === 200 && result.body.presignedUrl) {
      setAudioUrl(result.body.presignedUrl);
    } else {
      toast.error('Failed to generate file URL');
    }
  };

  if (!filePath) return null;

  return (
    <Box hStack>
      {!filePath.endsWith('.json') && (
        <HStack>
          <TooltipLabel label="Enable audio player">
            <Button
              variant="outline"
              size="icon"
              onClick={getFile}
              disabled={!filePath}
            >
              <Headphones />
            </Button>
          </TooltipLabel>
          <AudioPlayer audioUrl={audioUrl} />
        </HStack>
      )}
    </Box>
  );
};
